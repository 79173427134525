import { ILocalization } from 'services/api/general/ILocalization';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import { t } from 'services/utils/translation';

export const extractGetMenuOrSubMenuName =
  (localization: ILocalization) =>
  (name: keyof typeof localization): string => {
    return localization?.[name]
      ? makeFirstLetterUppercase(localization?.[name])
      : t(
          'Обратитесь, пожалуйста, к администратору для правильного наименования'
        );
  };
