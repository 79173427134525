import { IAccessPolicyStatementCrunch } from 'services/api/staff/access-policy/IAccessPolicy';
import { IStartMenu } from 'services/interfaces/IMenuItemV2';
import { actionCodeForDisplayFolder } from '../useLoadMenu';

export const extractFilterSubMenu =
  (
    currentEmployeeAccessPolicy: IAccessPolicyStatementCrunch,
    statementName: string
  ) =>
  (menu: IStartMenu): boolean => {
    return currentEmployeeAccessPolicy?.[statementName]?.[menu.name]?.includes(
      actionCodeForDisplayFolder
    );
  };
