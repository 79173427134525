import { ISubMenuItemV2 } from 'services/interfaces/IMenuItemV2';

export const isFavoriteMenuItem = (
  targetItemName: string,
  myFavoriteListFolder?: ISubMenuItemV2[]
) =>
  !myFavoriteListFolder
    ? false
    : myFavoriteListFolder.findIndex(
        (item: ISubMenuItemV2) => item.name === targetItemName
      ) !== -1;
