import { useCallback, useEffect, useMemo } from 'react';
import { sortMenu } from 'services/paths';
import { useDispatch, useSelector } from 'react-redux';
import {
  PATH_CONTROLLER_FOLDER,
  PATH_DOCUMENTS_FILE_BUCKET_SCHEMA,
  PATH_EXTERNAL_REQUEST_FOLDER,
  PATH_EXTERNAL_REQUEST_RESPONSIBLE_DEPARTMENT,
  PATH_EXTERNAL_REQUEST_TABLE,
  PATH_EXTERNAL_REQUEST_WORK_CATEGORY,
  PATH_ORGANIZATIONS,
  PATH_ORGANIZATIONS_ACCOUNTS,
  PATH_ORGANIZATIONS_BILLING_ACCOUNT_TYPE,
  PATH_ORGANIZATIONS_COUNTERPARTY,
  PATH_ORGANIZATIONS_EVENT,
  PATH_ORGANIZATIONS_EVENT_TYPE,
  PATH_PERSONNEL,
  PATH_PERSONNEL_ACCESS_POLICY,
  PATH_PERSONNEL_EMPLOYEE,
  PATH_PERSONNEL_JOB_TITLE,
  PATH_TASK_FOLDER,
  PATH_TASK_STATUSES,
  PATH_TASK_TABLE,
  PATH_TASK_TYPES,
  PATH_VEHICLE_TYPE,
  PATH_VEHICLES_FOLDER,
  PATH_VEHICLE,
  PATH_VEHICLE_BOOKING,
  PATH_CONTROLLER_PAGE,
  PATH_DOCUMENTS_FOLDER,
} from '../../services/pathConstants';
import {
  getCurrentEmployeeAccessPolicyFromStore,
  getMenuFavoritesFromStore,
} from 'store/general/selectors';
import { ILocalization } from '../../services/api/general/ILocalization';
import { getLocalizationFromStore } from '../../store/general/selectors';
import {
  IMenuItemV2,
  IStartMenu,
  ISubMenuItemV2,
} from '../../services/interfaces/IMenuItemV2';
import { loadMenu, removeMenu } from '../../store/general/actions';
import useMyListOfFavoriteFolders from '../useFavoriteFolders';
import { getInstallationPkFromStore } from '../../store/auth/selectors';
import { catchErrorFromFetch } from '../../services/utils/catchAndRegError/catchAndRegError';
import { isFavoriteMenuItem } from './utils/isFavoriteMenuItem';
import { extractGetMenuOrSubMenuName } from './utils/extractGetMenuOrSubMenuName';
import { extractFilterSubMenu } from './utils/extractFilterSubMenu';

// @TODO REFACTORING

const SUBMENU_EXTERNAL_REQUEST = [
  {
    name: 'ExternalRequest',
    url: PATH_EXTERNAL_REQUEST_TABLE,
    weight: 100,
  },

  {
    name: 'WorkCategory',
    url: PATH_EXTERNAL_REQUEST_WORK_CATEGORY,
    weight: 200,
  },
  {
    name: 'ResponsibleDepartment',
    url: PATH_EXTERNAL_REQUEST_RESPONSIBLE_DEPARTMENT,
    weight: 300,
  },
];

const SUBMENU_TASK = [
  {
    name: 'InternalTask',
    url: PATH_TASK_TABLE,
    weight: 100,
  },
  {
    name: 'InternalTaskType',
    url: PATH_TASK_TYPES,
    weight: 200,
  },
  {
    name: 'InternalTaskStatus',
    url: PATH_TASK_STATUSES,
    weight: 300,
  },
];

const SUBMENU_PERSONAL = [
  {
    name: 'Employee',
    url: PATH_PERSONNEL_EMPLOYEE,
    weight: 400,
  },
  {
    name: 'AccessPolicy',
    url: PATH_PERSONNEL_ACCESS_POLICY,
    weight: 500,
  },
  {
    name: 'JobTitle',
    url: PATH_PERSONNEL_JOB_TITLE,
    weight: 600,
  },
];

const SUBMENU_ORGANIZATIONS = [
  {
    name: 'BillingAccount',
    url: PATH_ORGANIZATIONS_ACCOUNTS,
    weight: 500,
  },
  {
    name: 'Counterparty',
    url: PATH_ORGANIZATIONS_COUNTERPARTY,
    weight: 600,
  },

  {
    name: 'BillingAccountType',
    url: PATH_ORGANIZATIONS_BILLING_ACCOUNT_TYPE,
    weight: 606,
  },
  {
    name: 'Event',
    url: PATH_ORGANIZATIONS_EVENT,
    weight: 608,
  },
  {
    name: 'EventType',
    url: PATH_ORGANIZATIONS_EVENT_TYPE,
    weight: 608,
  },
];

const SUBMENU_VEHICLE = [
  {
    name: 'Vehicle',
    url: PATH_VEHICLE,
    weight: 701,
  },
  {
    name: 'VehicleType',
    url: PATH_VEHICLE_TYPE,
    weight: 702,
  },
  {
    name: 'Booking',
    url: PATH_VEHICLE_BOOKING,
    weight: 703,
  },
];

const SUBMENU_DOCUMENTS = [
  {
    name: 'FileBucketSchema',
    url: PATH_DOCUMENTS_FILE_BUCKET_SCHEMA,
    weight: 801,
  },
];

const SUBMENU_CONTROLLERS = [
  {
    name: 'Controller',
    url: PATH_CONTROLLER_PAGE,
    weight: 901,
  },
];

const MENU: IStartMenu[] = [
  {
    name: 'external_request',
    url: PATH_EXTERNAL_REQUEST_FOLDER,
    weight: 100,
    submenu: SUBMENU_EXTERNAL_REQUEST,
  },
  {
    name: 'internal_task',
    url: PATH_TASK_FOLDER,
    weight: 200,
    submenu: SUBMENU_TASK,
  },
  {
    name: 'personnel',
    url: PATH_PERSONNEL,
    weight: 300,
    submenu: SUBMENU_PERSONAL,
  },

  {
    name: 'accounts',
    url: PATH_ORGANIZATIONS,
    weight: 400,
    submenu: SUBMENU_ORGANIZATIONS,
  },
  {
    name: 'vehicles',
    url: PATH_VEHICLES_FOLDER,
    weight: 500,
    submenu: SUBMENU_VEHICLE,
  },
  {
    name: 'documents',
    url: PATH_DOCUMENTS_FOLDER,
    weight: 700,
    submenu: SUBMENU_DOCUMENTS,
  },
  {
    name: 'controller',
    url: PATH_CONTROLLER_FOLDER,
    weight: 600,
    submenu: SUBMENU_CONTROLLERS,
  },
];

export const actionCodeForDisplayFolder = 'R';

const extractReduceSubMenu =
  (
    filter: (menu: IStartMenu) => boolean,
    getMenuName: (name: keyof ILocalization) => string,
    myFavoriteListFolder?: ISubMenuItemV2[]
  ) =>
  (acc: ISubMenuItemV2[], cur: IStartMenu): ISubMenuItemV2[] => {
    if (filter(cur)) {
      const newMenuItem = {
        ...cur,
        title: getMenuName(cur.name as keyof ILocalization),
        isFavorite: isFavoriteMenuItem(cur.name, myFavoriteListFolder),
      };
      acc.push(newMenuItem);
    }
    return acc;
  };

const filterMenu = (menu: IMenuItemV2) =>
  menu?.submenu && menu.submenu?.length > 0;

const useLoadMenu = (
  authDomain?: number,
  showMenu: boolean = true,
  blockCall: boolean = false
): (() => void) => {
  const installationPk = useSelector(getInstallationPkFromStore);
  const localization = useSelector(getLocalizationFromStore);
  const myFavoriteListFolderFromStore = useSelector(getMenuFavoritesFromStore);

  const [myFavoriteFolderListFromLocalStorage] = useMyListOfFavoriteFolders();

  const favorites =
    myFavoriteListFolderFromStore?.length > 0
      ? myFavoriteListFolderFromStore
      : myFavoriteFolderListFromLocalStorage;

  const getMenuOrSubMenuName = useMemo(() => {
    return extractGetMenuOrSubMenuName(localization);
  }, [localization]);

  const dispatch = useDispatch();

  const accessPolicyStatement = useSelector(
    getCurrentEmployeeAccessPolicyFromStore
  );
  const extractMenu = useCallback(() => {
    try {
      if (accessPolicyStatement === undefined) {
        dispatch(removeMenu());
      }
      const filteredSubMenu = MENU.map((menu: IStartMenu) => {
        const filterSubMenu = extractFilterSubMenu(
          accessPolicyStatement,
          menu.name
        );
        const reduceSubMenu = extractReduceSubMenu(
          filterSubMenu,
          getMenuOrSubMenuName,
          favorites
        );
        return {
          ...menu,
          submenu: menu?.submenu?.reduce(reduceSubMenu, []),
          title: getMenuOrSubMenuName(menu.name as keyof ILocalization),
        };
      });
      const filteredMenu = filteredSubMenu.filter(filterMenu);
      dispatch(loadMenu(sortMenu(filteredMenu)));
    } catch (error) {
      dispatch(removeMenu());
      catchErrorFromFetch(
        error as string,
        'Не удалось загрузить меню. Обратитесь, пожалуйста, к Администратору'
      );
    }
  }, [accessPolicyStatement, dispatch, getMenuOrSubMenuName, favorites]);
  useEffect(() => {
    if (authDomain && !blockCall) {
      if (showMenu) {
        extractMenu();
      } else {
        dispatch(removeMenu());
      }
    }
  }, [authDomain, blockCall, dispatch, extractMenu, showMenu, installationPk]);

  return extractMenu;
};

export default useLoadMenu;
